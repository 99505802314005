export const FORM_FEEDBACK_MESSAGES: {
  email: string;
  required: string;
  wrongNumber: string;
  onlyLowerCase: string;
  noNumbers: string;
  noCapitalLetters: string;
  incorrectPassword: string;
} = {
  email: 'E-mail address is not correct',
  required: 'This field is required',
  wrongNumber: 'Enter a valid phone number with a country code',
  onlyLowerCase: 'Only lowercase and space allowed',
  noNumbers: 'No numbers allowed',
  noCapitalLetters: 'No capital letters allowed',
  incorrectPassword: 'Unable to log in with provided credentials',
};
