import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { DictionaryInterface } from '../../../../interfaces/dictionary.interface';
import { FormFeedbackComponent } from '../form-feedback/form-feedback.component';
import { FormLabelComponent } from '../form-label/form-label.component';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    NgIf,
    FormLabelComponent,
    MatInput,
    FormsModule,
    ReactiveFormsModule,
    FormFeedbackComponent,
  ],
})
export class FormInputComponent implements ControlValueAccessor {
  @Input() formControl: FormControl<string | number | null>;
  @Input() label = '';
  @Input() tooltip = '';
  @Input() placeholder = '';
  @Input() type = 'text';
  @Input() required = false;
  @Input() isMinimalVersion = false;
  @Input() browserAutocomplete = '';
  @Input() customErrorMessages: DictionaryInterface<string> = {};

  registerOnChange(): void {}

  registerOnTouched(): void {}

  writeValue(): void {}

  onInput(target: EventTarget | null): void {
    if ((target as HTMLInputElement)?.value === '') {
      this.formControl.reset(null);
    }
  }
}
