import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { FormInputComponent } from '../form-input/form-input.component';

@Component({
  selector: 'app-form-search',
  template: `<app-form-input
      [formControl]="formControl"
      [isMinimalVersion]="isMinimalVersion"
      [type]="'search'"
      placeholder="Search&hellip;"
    ></app-form-input><button
      (click)="formControl.setValue('')"
      class="form-search-x"
    >&#xe5cd;</button>`,
  styleUrls: ['./form-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormSearchComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    FormInputComponent,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class FormSearchComponent implements ControlValueAccessor {
  @Input() formControl: FormControl<string | number | null>;
  @Input() isMinimalVersion = false;

  registerOnChange(): void {}

  registerOnTouched(): void {}

  writeValue(): void {}
}
