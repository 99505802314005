<div
  [class.form-input--minimal]="isMinimalVersion"
  class="form-input"
>
  <app-form-label
    *ngIf="label"
    [label]="label"
    [tooltip]="tooltip"
    class="form-input-label"
  ></app-form-label>

  <input
    matInput
    [formControl]="formControl"
    [placeholder]="placeholder"
    [type]="type"
    [autocomplete]="browserAutocomplete"
    (input)="onInput($event.target)"
  />

  <app-form-feedback
    [formControl]="formControl"
    [customErrorMessages]="customErrorMessages"
  ></app-form-feedback>
</div>
